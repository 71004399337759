.event {
    display: flex;
    flex-direction: column;

    .event-header {
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border: none;
        outline: none;
    }

    .qr-code {
        margin: 8px;
    }

    .event-body {
        overflow-y: scroll;
        max-height: 350px;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .event-item {
        border: 1px solid #afafaf;
        padding: 4px;
        margin: 2px 0px;
    }
}