// style.scss
@import "../node_modules/nes.css/css/nes.css";
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Tint&display=swap');

* {
  font-family: "Black Ops One", system-ui;
}

a {
  text-decoration: none;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F7F7F7;
  width: 100%;
  max-width: 500px;
  margin: 0px auto;
  color: #0b111f;
}

body {
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.app {
  margin: 0px auto;
  max-width: 800px;
}

header {
  box-shadow: 2px 2px 12px 2px #888888;
  margin: 4px 0px 16px 0px;
  padding: 8px;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  z-index: 1000;
  a {
    color: #0b111f;
    &:hover {
      color: #3B5998;
      text-decoration: none;
    }
  }
}

.main {
  background: #fff;
  box-shadow: 2px 2px 12px 2px #888888;
  padding: 8px;
  border-radius: 8px;
}

.form-inline {
  display: flex;
  align-items: center;
  gap: 4px;
  align-items: baseline;
}

.form-break-line {
  display: grid;
  align-content: space-around;
  justify-content: center;
  align-items: baseline;
}

.item1 {
  background-color: #0b111f;
}

.room-code {
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #3B5998;
}


.tooltiptext {
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 16px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.container {
  padding: 8px;
}