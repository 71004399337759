.room-lead {
    p {
        display: inline;
    }
    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;

        &:focus+label {
            border: 2px dashed #0f4061;
        }

        &:checked+label {
            background-color: #bfb;
            border: 2px dashed rgb(2, 109, 2);
            border-color: rgb(35, 211, 35);
        }
    }

    input[type=checkbox] {
        display: none;
        &+label {
            display: inline-flex;
        }
        &:checked+label {
            background-color: #bfb;
            border: 2px dashed rgb(2, 109, 2);
            border-color: rgb(35, 211, 35);
        }
    }


    label {
        display: inline-block;
        background-color: #d5edfd;
        padding: 8px 16px;
        margin-right: 4px;
        font-size: 16px;
        border: 2px solid #000000;
        border-radius: 4px;
        border-style: dashed;

        &:hover {
            background-color: #dfd;
        }
    }
}